// import logo from './logo.svg';
import logo from './assets/images/logo_birga.png';
import './App.css';
import LocationOnTwoToneIcon from '@mui/icons-material/LocationOnTwoTone';
import LocalPhoneTwoToneIcon from '@mui/icons-material/LocalPhoneTwoTone';
import EmailTwoToneIcon from '@mui/icons-material/EmailTwoTone';

function App() {
  return (
    <div className="App">
        <header className="App-header">

            <img src={logo} className="App-logo" alt="logo"/>
            <p>
                <code>Embark on an Adventure with Us! </code><br/>
                <code>Our Website is Preparing for Liftoff. </code>
            </p>
            <p><code>Stay Tuned!</code></p>

            <dl>
                <dt><LocationOnTwoToneIcon/></dt>
                <dd>BirgaTour Co.,Ltd. <br/>
                    #14th floor, Union Building,<br/>
                    Sukhbaatar district, 1st khoroo,<br/>
                    Sunroad-62, Ulaanbaatar, Mongolia
                </dd>

                <dt><LocalPhoneTwoToneIcon/></dt>
                <dd>+(976) 9909-1166</dd>

                <dt><EmailTwoToneIcon/></dt>
                <dd>contact@birgatour.com</dd>
            </dl>
        </header>
    </div>
  );
}

export default App;
